<template>
  <DashboardTemplateCasual
    v-loading="loading"
    element-loading-text="Loading..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    :title="$t('message.statisticsReport')"
  >
    <h1>{{ $t("message.statisticsReport") }}</h1>
    <el-row :gutter="10" class="pd-b-3" justify align="middle">
      <el-col :lg="8" :md="12" :sm="12" :xs="24" class="pd-b-4">
        <el-select
          v-model="filter.sessionType"
          @change="checkFilterList"
          :placeholder="$t('statisticsReport.findSessionType')"
          clearable
          class="w-100"
        >
          <el-option label="Casual" value="Casual"> </el-option>
          <el-option label="Period" value="Period"> </el-option>
        </el-select>
      </el-col>
      <el-col :lg="8" :md="12" :sm="12" :xs="24" class="pd-b-4">
        <el-select
          v-model="filter.test"
          @change="changeTest"
          :placeholder="$t('statisticsReport.pleaseSelectTest')"
          class="w-100"
        >
          <el-option
            v-for="item in tests"
            :key="item._id"
            :label="item.name"
            :value="item._id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :lg="8" :md="12" :sm="12" :xs="24" class="pd-b-4">
        <el-select
          v-model="filter.device"
          multiple
          filterable
          remote
          reserve-keyword
          :placeholder="$t('statisticsReport.pleaseSelectLevel')"
          :remote-method="remoteMethodDevices"
          :loading="loadingDevice"
          class="w-100"
          clearable
          @change="checkFilterList"
        >
          <el-option
            v-for="item in devices"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>

    <div v-loading="loading">
      <el-row :gutter="10" class="pd-b-3" justify align="middle">
        <el-col :lg="12" :md="12" :sm="12" :xs="24" class="pd-b-4">
          <el-card class="statistic-card pending">
            <font-awesome-icon icon="fa-solid fa-gamepad" />
            <div>
              <span class="number">{{ playTotal | comma }}</span>
              <p class="title">{{ $t("statisticsReport.playTotal") }}</p>
            </div>
          </el-card>
        </el-col>
        <el-col :lg="12" :md="12" :sm="12" :xs="24" class="pd-b-4">
          <el-card class="statistic-card success">
            <font-awesome-icon icon="fa-solid fa-square-check" />
            <div>
              <span class="number">{{ playSucceed | comma }}</span>
              <p class="title">{{ $t("statisticsReport.playSucceed") }}</p>
            </div>
          </el-card>
        </el-col>
        <el-col :lg="12" :md="12" :sm="12" :xs="24" class="pd-b-4">
          <el-card class="statistic-card avg">
            <font-awesome-icon icon="fa-solid fa-chart-simple" />
            <div>
              <span class="number"
                >{{ avg | commaDec2 }} {{ $t("message.unitSecond") }}</span
              >
              <p class="title">{{ $t("statisticsReport.avg") }}</p>
              <span class="description">{{
                $t("statisticsReport.avgSDDescription")
              }}</span>
            </div>
          </el-card>
        </el-col>
        <el-col :lg="12" :md="12" :sm="12" :xs="24" class="pd-b-4">
          <el-card class="statistic-card sd">
            <font-awesome-icon icon="fa-solid fa-plus-minus" />
            <div>
              <span class="number"
                >{{ sd | commaDec2 }} {{ $t("message.unitSecond") }}</span
              >
              <p class="title">{{ $t("statisticsReport.sd") }}</p>
              <span class="description">{{
                $t("statisticsReport.avgSDDescription")
              }}</span>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <div>
        <h3>{{ $t("statisticsReport.tableName") }}</h3>
        <el-table :data="tableFrequencyData" border>
          <el-table-column
            prop="level"
            :label="$t('statisticsReport.level')"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="frequency"
            :label="$t('statisticsReport.frequency')"
            header-align="center"
            align="right"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.frequency | comma }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="cumulativeFrequency"
            :label="$t('statisticsReport.cumulativeFrequency')"
            header-align="center"
            align="right"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.cumulativeFrequency | comma }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="percentFrequency"
            :label="$t('statisticsReport.percentFrequency')"
            header-align="center"
            align="right"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.percentFrequency | commaDec2 }}%</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="percentCumulativeFrequency"
            :label="$t('statisticsReport.percentCumulativeFrequency')"
            header-align="center"
            align="right"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.percentCumulativeFrequency | commaDec2 }}%</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="avg"
            :label="$t('statisticsReport.tableAVG')"
            header-align="center"
            align="right"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.avg | commaDec2 }} {{ $t("message.unitSecond") }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="sd"
            :label="$t('statisticsReport.tableSD')"
            header-align="center"
            align="right"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.sd | commaDec2 }} {{ $t("message.unitSecond") }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- <div>
        <h3>
          ตารางแสดงค่าเฉลี่ยและส่วนเบี่ยงเบนมาตรฐานของเวลาที่ใช้ในการทำแบบทดสอบ
          แยกตาม Max Level ที่ทําได้
        </h3>
        <el-table :data="tableAVGSD" border>
          <el-table-column
            prop="level"
            label="เลเวล"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="avg"
            label="ค่าเฉลี่ย"
            header-align="center"
            align="right"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.avg | commaDec2 }} วินาที</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="sd"
            label="ส่วนเบี่ยงเบนมาตรฐาน"
            header-align="center"
            align="right"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.sd | commaDec2 }} วินาที</div>
            </template>
          </el-table-column>
        </el-table>
      </div> -->
    </div>
  </DashboardTemplateCasual>
</template>

<script>
import DashboardTemplateCasual from "@/template/DashboardTemplateCasual";
import { HTTP } from "@/service/axios";

export default {
  components: {
    DashboardTemplateCasual,
  },
  data() {
    return {
      loading: true,
      tableFrequencyData: [],
      tableAVGSD: [],
      filter: {
        sessionType: "Casual",
        device: [],
        test: "",
      },
      loadingDevice: false,
      devices: [],
      deviceConst: [
        { label: this.$t("statisticsReport.computer"), value: "Desktop" },
        { label: this.$t("statisticsReport.tablet"), value: "Tablet" },
        { label: this.$t("statisticsReport.mobile"), value: "Mobile" },
      ],
      debounce: null,
      playTotal: 0,
      playSucceed: 0,
      avg: 0,
      sd: 0,
      totalLevel: 0,
      tests: [],
    };
  },
  watch: {
    "$i18n.locale"() {
      this.$store.commit("SET_BREADCRUMBS", [
        { path: "/", name: "home", i18n: true },
        { path: null, name: this.$t("message.statisticsReport") },
      ]);

      this.deviceConst = [
        { label: this.$t("statisticsReport.computer"), value: "Desktop" },
        { label: this.$t("statisticsReport.tablet"), value: "Tablet" },
        { label: this.$t("statisticsReport.mobile"), value: "Mobile" },
      ];
    },
  },
  mounted() {
    this.devices = this.deviceConst;

    this.$store.commit("SET_BREADCRUMBS", [
      { path: "/", name: "home", i18n: true },
      { path: null, name: this.$t("message.statisticsReport") },
    ]);

    this.fetchTests();
  },
  destroyed() {
    this.$store.commit("SET_BREADCRUMBS", []);
  },
  methods: {
    async fetchTests() {
      try {
        let res = await HTTP.get(`/tests/count/level`);

        if (res.data.success) {
          this.tests = res.data.result.rows;

          const item = this.tests[0];

          this.filter.test = item._id;

          this.totalLevel = item.totalLevel;
          this.fetchActions();
        }
      } catch (e) {
        const error = e.response;
        console.error(error);
      }
    },
    changeTest() {
      const find = this.tests.find((f) => f._id === this.filter.test);
      if (typeof find !== "undefined") {
        this.totalLevel = find.totalLevel;
        this.checkFilterList();
      }
    },

    async fetchActions() {
      try {
        let obj = {
          sessionType: this.filter.sessionType,
          device: this.filter.device,
          test: this.filter.test,
        };

        let res = await HTTP.post(`/log/actions/statistics`, obj);

        if (res.data.success) {
          this.playTotal = 0;
          this.playSucceed = 0;
          this.avg = 0;
          this.sd = 0;

          let totalSum = 0;
          let durationResponse = [];

          let datas = [];
          res.data.result.map((item) => {
            let filter = res.data.result.filter(
              (f) => f.gameSessionId === item.gameSessionId
            );
            let sort = filter.sort((a, b) => {
              if (
                new Date(a.timeStartResponse).getTime() >
                new Date(b.timeStartResponse).getTime()
              )
                return -1;
              if (
                new Date(a.timeStartResponse).getTime() <
                new Date(b.timeStartResponse).getTime()
              )
                return 1;
              return 0;
            });

            let find = datas.find(
              (f) => f.gameSessionId === item.gameSessionId
            );

            if (typeof find === "undefined" && sort[0].level > 0) {
              this.playTotal++;
              if (sort[0].gameSessionFinished) {
                this.playSucceed++;
              }

              let sum =
                (new Date(sort[0].timeLastResponse).getTime() -
                  new Date(sort[sort.length - 1].timeStartResponse).getTime()) /
                1000;

              totalSum += sum;

              durationResponse.push(sum);

              sort[0]["durationResponse"] = sum;
              datas.push(sort[0]);
            }
          });

          this.tableFrequencyData = [];
          this.tableAVGSD = [];
          for (let level = 1; level <= this.totalLevel; level++) {
            const filter = datas.filter((f) => f.maxLevel === level);
            const beforeCumulativeFrequency =
              this.tableFrequencyData.length > 0
                ? this.tableFrequencyData[this.tableFrequencyData.length - 1]
                    .cumulativeFrequency
                : 0;

            const obj = {
              level: level,
              frequency: filter.length,
              cumulativeFrequency: beforeCumulativeFrequency + filter.length,
              avg: 0,
              sd: 0,
            };

            let durationResponseSum = 0;
            let durationResponseAVGSD = [];
            let totalDurationResponse = 0;

            filter.map((item) => {
              durationResponseSum += item.durationResponse;
              durationResponseAVGSD.push(item.durationResponse);
              totalDurationResponse++;
            });

            let durationResponseSVG =
              totalDurationResponse > 0
                ? durationResponseSum / totalDurationResponse
                : 0;

            const objAVGSD = {
              level: level,
              avg: durationResponseSVG,
              sd:
                totalDurationResponse < 2
                  ? 0
                  : this.callSD(
                      durationResponseAVGSD,
                      totalDurationResponse,
                      durationResponseSVG
                    ),
            };

            obj.avg = durationResponseSVG;
            obj.sd =
              totalDurationResponse < 2
                ? 0
                : this.callSD(
                    durationResponseAVGSD,
                    totalDurationResponse,
                    durationResponseSVG
                  );

            this.tableFrequencyData.push(obj);
            this.tableAVGSD.push(objAVGSD);
          }

          this.tableFrequencyDat = this.tableFrequencyData.map((item) => {
            item["percentFrequency"] =
              (item.frequency /
                this.tableFrequencyData[this.tableFrequencyData.length - 1]
                  .cumulativeFrequency) *
              100;

            item["percentCumulativeFrequency"] =
              (item.cumulativeFrequency /
                this.tableFrequencyData[this.tableFrequencyData.length - 1]
                  .cumulativeFrequency) *
              100;
            return item;
          });

          this.avg = this.playTotal === 0 ? 0 : totalSum / this.playTotal;
          this.sd =
            this.playTotal < 2
              ? 0
              : this.callSD(durationResponse, this.playTotal, this.avg);
        }
      } catch (e) {
        console.error(e);
        return false;
      } finally {
        this.loading = false;
      }
    },
    callSD(datas, total, avg) {
      let totalPowDifference = 0;
      datas.map((item) => {
        let difference = item - avg;

        let powDifference = difference * difference;
        totalPowDifference += powDifference;
      });

      let sd = totalPowDifference / (total - 1);

      let radicalsNthRoot = Math.pow(sd, 1 / 2);

      return Math.floor(radicalsNthRoot * 100) / 100;
    },
    remoteMethodDevices(query) {
      if (query !== "") {
        this.loadingDevice = true;
        setTimeout(() => {
          this.loadingDevice = false;

          this.devices = this.deviceConst.filter((item) => {
            return item.value.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.devices = this.deviceConst;
      }
    },
    checkFilterList() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.page = 1;
        this.loading = true;
        this.fetchActions();
      }, 600);
    },
  },
};
</script>

